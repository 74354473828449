import React from "react"
import Typewriter from 'typewriter-effect';
import { Helmet } from 'react-helmet'

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

import contactimg from '../images/contact-office.png'

export default function ContactPage() {
    return (
      <div>
        <Seo title="3BLACKDOT Careers" />
        <Helmet bodyAttributes={{ class: 'contact-page' }} />
        <Header />
        <div className="bg-amber-100">
          <div class="pt-16" />

          <div className="container mx-auto">
            
            <div className="grid grid-cols-2 lg:grid-cols-12 p-10 text-stone-100">
              
              <div className="col-span-5">
                <img src={contactimg} className="mb-10 lg:mb-0" />
              </div>
              <div className="col-span-1"></div>
              <div className="col-span-6 lg:mb-0">
                <h2 className="knockoutHTF67 w-full text-5xl lg:text-6xl uppercase tracking-wide text-black m-0">3BLACKDOT</h2>
                <div className="inline-block px-3 bg-black">
                  <h2 className="knockoutHTF67 text-5xl lg:text-6xl m-0 uppercase tracking-wide text-white">
                    <Typewriter
                      onInit={(typewriter) => {
                        typewriter.typeString('CONTACT')
                          .pauseFor(2000)
                          .start();
                      }}
                    />
                  </h2>
                </div>

                <div className="grid gap-5">

                  <div className="text-black pt-5">
                    <h2 className="text-md uppercase m-0">Office</h2>
                    <div className="text-sm">925 N La Brea Ave, 6th Floor <br />Los Angeles, CA 90038</div>
                  </div>
                  
                  <div className="text-black ">
                    <h2 className="text-md uppercase m-0">Phone</h2>
                    <div className="text-sm">(323) 477-2510</div>
                  </div>

                  <div className="text-black ">
                    <h2 className="text-md uppercase m-0">Email</h2>
                    <div className="text-sm">contact@3blackdot.com</div>
                  </div>

                  <div className="text-black ">
                    <h2 className="text-md uppercase m-0">Join us</h2>
                    <div className="text-sm">recruitment@3blackdot.com</div>
                  </div>

                </div>

              </div>

            </div>            

          </div>

        </div>
        <Footer />
      </div>
    )
}
